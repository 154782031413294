import { mapCraftRelatedEntry } from '@src/helpers/craft'

const mapEntry = (entry, related) => {
  return {
    title: entry.title,
    description: entry.description,
    type: entry.typeHandle,
    links: entry.externalLinks,
    related: related && related.length ? related.map(mapCraftRelatedEntry) : [],
  };
};

export const mapCraft = (craft) => {
  return {
    entry: mapEntry(
      craft.organisation,
      craft.allEntries
        .filter(
          ({ __typename }) =>
            __typename !== "Craft_author_authors_Entry" &&
            __typename !== "Craft_organisation_organisations_Entry"
        )
        .filter(({ organisations }) => {
          if (
            organisations &&
            organisations.filter(({ id }) => id == craft.organisation.id).length
          ) {
            return true;
          } else {
            return false;
          }
        })
    ),
  };
};
